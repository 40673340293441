<template>
<div>
	<b-navbar
		id="navbar-home"
		toggleable="lg"
		title="Title"
		variant="secondary"
		class="sticky-top">
		<b-navbar-brand :to="{name: 'Home'}" class="mr-2">
			<img src="../../../assets/logo.png" alt="Logo">
		</b-navbar-brand>
		<b-navbar-toggle target="nav-home-collapse"></b-navbar-toggle>
		<b-collapse id="nav-home-collapse" is-nav>
			<b-navbar-nav>
				<b-nav-item :to="{name: 'DashboardHome'}" active-class="primary">Dashboard</b-nav-item>
				<b-nav-item :to="{name: 'Logs'}">Logs</b-nav-item>
				<b-nav-item :to="{name: 'Clients'}">Clients</b-nav-item>
			</b-navbar-nav>
			<b-navbar-nav class="ml-auto">
				<b-nav-item :to="{name: 'Login'}">Esci</b-nav-item>
			</b-navbar-nav>
		</b-collapse>
	</b-navbar>
</div>
</template>

<script>
export default {
	name: "NavBarHome"
}
</script>

<style scoped>

</style>
