import { render, staticRenderFns } from "./NavBarHome.vue?vue&type=template&id=340d445e&scoped=true"
import script from "./NavBarHome.vue?vue&type=script&lang=js"
export * from "./NavBarHome.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "340d445e",
  null
  
)

export default component.exports