<template>
<div>
	<nav-bar-home></nav-bar-home>
	<router-view></router-view>
</div>
</template>

<script>
import NavBarHome from './components/NavBarHome.vue';
export default {
	name: "Home",
	components: {
		NavBarHome
	}
}
</script>

<style scoped>

</style>
